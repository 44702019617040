/*
 * For debugging while BrowserPrint is not running:
localStorage.setItem('DEFAULT_QUEUE_STATUS', 'PAUSED')
localStorage.setItem('DISABLE_ZEBRA', '1')
*/

import { ADMIN } from 'caps'
import { Loading } from 'components'
import {
  useApi,
  useBarcodeScanner,
  useInterval,
  usePageTitle,
  useStateful,
} from 'hooks'
import type { ApiCall } from 'hooks'
import { useCallback, useContext, useEffect, useReducer } from 'react'
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Progress,
  Row,
} from 'reactstrap'
import { getStorage } from 'utils/storage'
import AuthContext from './../../AuthContext'
import PrintCard from './PrintCard'
import PrinterSetup from './PrinterSetup'
import StatsCard from './StatsCard'
import LeaderboardCard from './LeaderboardCard'
import Step1 from './Step1Scan'
import Step2 from './Step2NumPlants'
import Step3 from './Step3Addons'
import Step4 from './Step4Boxes'
import barcodes, { type BarcodeType } from './barcodes'
import usePrinterQueue, { type PrinterQueueType } from './usePrinterQueue'
import type {
  ApiCarrier,
  ApiPackageSize,
  ApiShipperStats,
} from '@ttc/api/shipments'

/* For debugging. */
const persistState = false

const barcodeSheetUrl = `${global.appConfig.wpBaseUrl}/wp-json/tadmin/v1/barcode-sheet?format=svg&`

const [setItem, getItem] = getStorage('shipNow')

export type State = {
  stepIndex: number
  orderData: any
  didConfirmNumPlants: boolean
  didConfirmAddons: boolean
  didVoid: boolean
  boxes: { [sku: string]: number }
  numBoxes: number
  packageSizes: ApiPackageSize[]
  showSetup: boolean
  numBoxesShipped: number
  numPlantsShipped: number
  isAdvancedShipping: boolean
  showLabelActionButtons: boolean
  carriers: ApiCarrier[]
  carrierId: string | null
  serviceCode: string | null
  packageCode: string | null
  weight: number
  stats: any
  allowMultipleLabels: boolean
}

export type StepProps = {
  state: State
  dispatch: React.Dispatch<Action>
  printQueue: PrinterQueueType
  getOrderData: ApiCall
  fetchOrderData: (orderNumber: string) => void
  getStats: ApiCall
  reloadOrderData: () => void
}

const initialState = {
  stepIndex: 0,
  orderData: null,
  didConfirmNumPlants: false,
  didConfirmAddons: false,
  didVoid: false,
  boxes: {},
  numBoxes: 0,
  packageSizes: [],
  showSetup: false,
  numBoxesShipped: 0,
  numPlantsShipped: 0,
  isAdvancedShipping: false,
  showLabelActionButtons: false,
  carriers: [],
  carrierId: null,
  serviceCode: null,
  packageCode: null,
  weight: 0,
  stats: {},
  allowMultipleLabels: false,
}

const init = () => {
  const state = persistState ? getItem('state') : null
  if (state) {
    try {
      return { ...JSON.parse(state) }
    } catch (_e) {
      /* Ignore */
    }
  }

  return { ...initialState }
}

const pageTitle = 'Ship Now'

const steps = [Step1, Step2, Step3, Step4]

const updateState = (state: State) => {
  let stepIndex = 0

  if (state.orderData != null && state.orderData.label != null) {
    stepIndex = 3
  } else {
    if (state.orderData != null) {
      stepIndex = 1
    }

    if (state.didConfirmNumPlants) {
      stepIndex = 2
    }

    if (state.didConfirmNumPlants && state.didConfirmAddons) {
      stepIndex = 3
    }
  }

  const numBoxes = Object.keys(state.boxes).reduce(
    (a, k) => a + Number(state.boxes[k]),
    0,
  )

  return { ...state, stepIndex, numBoxes }
}

export type Action =
  | { type: 'RESET' }
  | { type: 'SET_ORDER_DATA'; orderData: any }
  | { type: 'UPDATE_ORDER_DATA'; orderData: any }
  | { type: 'SET_LABEL'; label: { id: string } | null }
  | { type: 'ALLOW_MULTIPLE_LABELS' }
  | { type: 'DID_VOID' }
  | { type: 'CONFIRM_NUM_PLANTS' }
  | { type: 'CONFIRM_ADDONS' }
  | { type: 'RESET_BOX_COUNT' }
  | { type: 'INC_BOX_COUNT'; sku: string }
  | { type: 'SET_BOX_COUNT'; sku: string; count: number }
  | { type: 'SET_PACKAGE_SIZES'; sizes: ApiPackageSize[] }
  | { type: 'SET_STATS'; stats: ApiShipperStats[] }
  | { type: 'TOGGLE_SHOW_SETUP' }
  | { type: 'SET_ADVANCED_SHIPPING' }
  | { type: 'TOGGLE_LABEL_ACTIONS' }
  | { type: 'SET_CARRIERS'; carriers: ApiCarrier[] }
  | { type: 'SET_CARRIER'; carrierId: string }
  | { type: 'SET_SERVICE_CODE'; serviceCode: string }
  | { type: 'SET_PACKAGE_CODE'; packageCode: string }
  | { type: 'SET_WEIGHT'; weight: number }

const _reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'RESET': {
      return {
        ...initialState,
        packageSizes: state.packageSizes,
        stats: state.stats,
      }
    }
    case 'SET_ORDER_DATA': {
      return updateState({
        ...state,
        didVoid: false,
        orderData: action.orderData,
      })
    }
    case 'UPDATE_ORDER_DATA': {
      return updateState({
        ...state,
        orderData: action.orderData,
      })
    }
    case 'SET_LABEL': {
      const { label } = action

      return updateState({
        ...state,
        didVoid: false,
        orderData: { ...state.orderData, label },
      })
    }
    case 'ALLOW_MULTIPLE_LABELS': {
      return { ...state, allowMultipleLabels: true }
    }
    case 'DID_VOID': {
      return updateState({
        ...state,
        didVoid: true,
        boxes: {},
        orderData: { ...state.orderData, label: null },
      })
    }
    case 'CONFIRM_NUM_PLANTS': {
      return updateState({ ...state, didConfirmNumPlants: true })
    }
    case 'CONFIRM_ADDONS': {
      return updateState({ ...state, didConfirmAddons: true })
    }
    case 'RESET_BOX_COUNT': {
      return updateState({ ...state, boxes: {} })
    }
    case 'INC_BOX_COUNT': {
      const sku = action.sku.toUpperCase()

      const boxes = { ...state.boxes }
      if (!Object.hasOwn(boxes, sku)) {
        boxes[sku] = 0
      }
      boxes[sku] += 1
      return updateState({ ...state, boxes })
    }
    case 'SET_BOX_COUNT': {
      const sku = action.sku.toUpperCase()

      const boxes = { ...state.boxes }
      boxes[sku] = action.count
      return updateState({ ...state, boxes })
    }
    case 'SET_PACKAGE_SIZES': {
      const packageSizes = action.sizes.map((s) => {
        const isUniqueLength =
          action.sizes.filter((a) => a.length === s.length).length === 1

        const label = isUniqueLength ? s.length : `${s.length}x${s.width}`

        return { ...s, label }
      })

      return updateState({ ...state, packageSizes })
    }
    case 'SET_STATS': {
      return updateState({ ...state, stats: action.stats })
    }
    case 'TOGGLE_SHOW_SETUP': {
      return updateState({ ...state, showSetup: !state.showSetup })
    }
    case 'SET_ADVANCED_SHIPPING': {
      return updateState({
        ...state,
        isAdvancedShipping: true,
        orderData: { ...state.orderData, label: null },
        allowMultipleLabels: true,
        didConfirmNumPlants: true,
        didConfirmAddons: true,
      })
    }
    case 'TOGGLE_LABEL_ACTIONS': {
      return updateState({
        ...state,
        showLabelActionButtons: !state.showLabelActionButtons,
      })
    }
    case 'SET_CARRIERS': {
      const { carriers } = action

      let s = { ...state, carriers }

      if (s.carrierId == null && carriers.length) {
        const carrierId = carriers[0].carrier_id
        const serviceCode = carriers[0].services[0].service_code
        const packageCode = carriers[0].packages[0].package_code

        s = { ...s, carrierId, serviceCode, packageCode }
      }

      return updateState(s)
    }
    case 'SET_CARRIER': {
      let s = { ...state, carrierId: action.carrierId }

      const carrier = s.carriers.find((c) => c.carrier_id === s.carrierId)

      if (carrier != null) {
        const serviceCode = carrier.services[0].service_code
        const packageCode = carrier.packages[0].package_code

        s = { ...s, serviceCode, packageCode }
      }

      return updateState(s)
    }
    case 'SET_SERVICE_CODE': {
      return updateState({ ...state, serviceCode: action.serviceCode })
    }
    case 'SET_PACKAGE_CODE': {
      return updateState({ ...state, packageCode: action.packageCode })
    }
    case 'SET_WEIGHT': {
      return updateState({ ...state, weight: action.weight })
    }
    default:
      return state
  }
}

const reducer = (state: State, action: Action) => {
  const ret = _reducer(state, action)

  if (persistState) {
    setItem('state', JSON.stringify(ret))
  }

  return ret
}

const ShipNow = () => {
  const { hasCap } = useContext(AuthContext)

  const printQueue = usePrinterQueue()

  usePageTitle(pageTitle)

  /* Prevent barcode scanner from pressing focussed buttons */
  useEffect(() => {
    const handle = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault()
      }
    }

    document.addEventListener('keydown', handle)

    return () => document.removeEventListener('keydown', handle)
  }, [])

  const getStats = useApi(
    () => ({ action: 'shippingreport_getShipperStats' }),
    null,
    () => ({ autoPerform: true }),
  )

  useEffect(() => {
    if (getStats.result) {
      dispatch({ type: 'SET_STATS', stats: getStats.result })
    }
  }, [getStats.result])

  useInterval(
    useCallback(async () => {
      getStats.performRequest()
    }, [getStats]),
    1000 * 5 * 60,
  )

  const getPackageSizes = useApi(
    () => ({ action: 'shipments_getPackageSizes' }),
    null,
    { autoPerform: true },
  )

  useEffect(() => {
    if (getPackageSizes.result) {
      dispatch({ type: 'SET_PACKAGE_SIZES', sizes: getPackageSizes.result })
    }
  }, [getPackageSizes.result])

  const [state, dispatch] = useReducer(reducer, null, init)

  global.debugState = state

  const getOrderData = useApi(() => ({ action: 'shipments_scanToShip' }))

  const fetchOrderData = useCallback(
    async (orderNumber) => {
      const orderData = await getOrderData.performRequest({
        json: { orderNumber },
      })

      dispatch({
        type: 'SET_ORDER_DATA',
        orderData: orderData ? orderData : null,
      })
    },
    [getOrderData],
  )

  const reloadOrderData = useCallback(async () => {
    if (state.orderData?.orderNumber) {
      const orderData = await getOrderData.performRequest({
        json: { orderNumber: state.orderData.orderNumber },
      })

      dispatch({
        type: 'UPDATE_ORDER_DATA',
        orderData: orderData ? orderData : null,
      })

      getStats.performRequest()
    }
  }, [getOrderData, state.orderData, getStats])

  useBarcodeScanner(
    useCallback(
      (barcode) => {
        if (getOrderData.isLoading) {
          return false
        }

        if (barcode === 'TTCRESET') {
          getStats.performRequest()
          dispatch({ type: 'RESET' })
          return
        }

        const isOrderNumber = /^[0-9-]+(-S[0-9]+)?$/.test(barcode)

        if (isOrderNumber) {
          fetchOrderData(barcode)
        }
      },
      [fetchOrderData, getOrderData.isLoading, getStats],
    ),
  )

  const stepIndex = state.stepIndex
  const Step = steps[stepIndex]

  const handleClickCancel = useCallback(() => {
    getStats.performRequest()
    dispatch({ type: 'RESET' })
  }, [getStats])

  const handleClickBarcodeCommands = useCallback(() => {
    const codes = barcodes

    const codeAllowed = (b: BarcodeType) => {
      if (b.adminOnly === true) {
        return hasCap(ADMIN)
      }

      return true
    }

    const _codes = codes.filter((b) => codeAllowed(b))

    const params = _codes
      .map((b) => {
        const code = encodeURIComponent(b.code)
        const d = encodeURIComponent(b.desc)
        const d2 = encodeURIComponent(b.desc2)

        return `${code},${d},${d2}`
      })
      .map((a) => `codes[]=${a}`)
      .join('&')

    window.open(`${barcodeSheetUrl}${params}`, '_blank')
  }, [hasCap])

  const handleClickBarcodePackages = useCallback(() => {
    const codes = []

    for (const s of state.packageSizes) {
      const size = s.sku.replace('BOX', '')

      codes.push({ code: s.sku, desc: size, desc2: `Add Box ${size}` })
    }

    const params = codes
      .map((b) => {
        const code = encodeURIComponent(b.code)
        const d = encodeURIComponent(b.desc)
        const d2 = encodeURIComponent(b.desc2)

        return `${code},${d},${d2}`
      })
      .map((a) => `codes[]=${a}`)
      .join('&')

    window.open(`${barcodeSheetUrl}${params}`, '_blank')
  }, [state.packageSizes])

  const handleClickSetup = useCallback(() => {
    dispatch({ type: 'TOGGLE_SHOW_SETUP' })
  }, [])

  const statsStyle = useStateful('simple', 'statsStyle')

  const handleToggleStats = useCallback(() => {
    statsStyle.set(statsStyle.value === 'simple' ? 'leaderboard' : 'simple')
  }, [statsStyle])

  if (getPackageSizes.isLoading) {
    return null
  }

  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem active>Ship Now 🖳</BreadcrumbItem>
        <div className="actions">
          <Button onClick={handleClickSetup}>
            <i className="fa fa-print" />
          </Button>
          <Button onClick={handleClickBarcodeCommands}>
            <i className="fa fa-barcode" /> Commands
          </Button>
          <Button onClick={handleClickBarcodePackages}>
            <i className="fa fa-barcode" /> Packages
          </Button>
        </div>
      </Breadcrumb>
      <Container fluid>
        <PrinterSetup
          {...{ printQueue, isVisible: state.showSetup, state, dispatch }}
        />
        <div className="ship-now animated fadeIn">
          <Row>
            <Col xs="9">
              <div>
                Step {stepIndex + 1} of {steps.length}
              </div>
              <Progress
                className="mb-3"
                value={(stepIndex / (steps.length - 1)) * 100}
              />
              <Card>
                <CardHeader>
                  {state.orderData ? (
                    <>
                      Shipping OrderSplit{' '}
                      <b>
                        {state.orderData.orderNumber} -{' '}
                        {state.orderData.customerName}
                      </b>
                    </>
                  ) : (
                    <>Ship an Order</>
                  )}
                  {state.stepIndex > 0 ? (
                    <Button
                      onClick={handleClickCancel}
                      className="p-0 m-0 ml-2"
                      color="link"
                      size="sm"
                    >
                      Cancel
                    </Button>
                  ) : null}
                </CardHeader>
                <Step
                  {...{
                    state,
                    dispatch,
                    printQueue,
                    getOrderData,
                    fetchOrderData,
                    getStats,
                    reloadOrderData,
                  }}
                />
                {getOrderData.error != null ? (
                  <CardBody className="text-center" style={{ fontSize: 24 }}>
                    <div className="alert alert-danger" role="alert">
                      {getOrderData.error}
                    </div>
                  </CardBody>
                ) : null}
                {getOrderData.isLoading ? <Loading /> : null}
              </Card>
            </Col>
            <Col xs="3">
              {statsStyle.value === 'leaderboard' ? (
                <LeaderboardCard
                  onToggleStats={handleToggleStats}
                  {...state.stats}
                />
              ) : (
                <StatsCard onToggleStats={handleToggleStats} {...state.stats} />
              )}
              <PrintCard {...{ printQueue }} />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  )
}

export default ShipNow
