import { DropDownFilterButton, TagFilterButton } from 'components'
import DateRangeFilterButton from 'components/Filters/DateRangeFilterButton'
import BoolFilterButton from 'containers/common/Filters/BoolFilterButton'
import CategoriesFilterButton from 'containers/common/Filters/CategoriesFilterButton'
import ProductAttributesFilterButton from 'containers/common/Filters/ProductAttributesFilterButton'
import type { ApiCall } from 'hooks'
import QuantityFilterButton from './QuantityFilterButton'
import SuppliersFilterButton from './SuppliersFilterButton'
import ZoneFilterButton from './ZoneFilterButton'
import DiscountFilterButton from './DiscountFilterButton'
import type { ApiTag } from '@ttc/api/tags'

// Note: draft products are not available in Manage Inventory.
const productStatuses = ['publish', 'private']

const advancedBoolFilters = [
  {
    name: 'isEnabled',
    label: 'Is Enabled',
    optionLabels: ['Disabled', 'Enabled'],
  },
  // variation-only filters:
  {
    name: 'isVirtual',
    label: 'Is Virtual',
    optionLabels: ['Not Virtual', 'Is Virtual'],
  },
  {
    name: 'isBox',
    label: 'Is Box',
    optionLabels: ['Not Box', 'Is Box'],
  },
  {
    name: 'isBlocked',
    label: 'Is Blocked',
    optionLabels: ['Not Blocked', 'Is Blocked'],
  },
  {
    name: 'hasVarDesc',
    label: 'Has Variation Description',
    optionLabels: ['No Variation Description', 'Has Variation Description'],
  },
]

export type ManageInventoryFiltersType = FiltersTypeWithUrlUpdater & {
  q: FilterType
  status: FilterType
  tags: FilterType
  categories: FilterType
  zones: FilterType
  isEnabled: FilterType
  isVirtual: FilterType
  isBox: FilterType
  range: FilterType
  suppliers: FilterType
  discount: FilterType
}

type FiltersProps = {
  filters: ManageInventoryFiltersType
  hasRangeFilter: boolean
  hiddenColumns: string[]
  visibleColumnIds: string[]
  apiLoadZones: ApiCall
  apiLoadDiscounts: ApiCall
  tags: ApiTag[]
}

export const Filters = (props: FiltersProps) => {
  const { tags, filters, hiddenColumns, visibleColumnIds, hasRangeFilter } =
    props

  return (
    <>
      <TagFilterButton
        {...{
          tags,
          filter: filters.tags,
        }}
      />
      {visibleColumnIds.includes('status') ? (
        <DropDownFilterButton
          label="Status"
          filter={filters.status}
          values={productStatuses}
        />
      ) : null}
      <CategoriesFilterButton
        {...{ filter: filters.categories as FilterType }}
      />
      <SuppliersFilterButton {...{ filter: filters.suppliers as FilterType }} />
      <ProductAttributesFilterButton {...{ filters }} />
      <QuantityFilterButton
        {...{
          filters,
          hiddenColumns,
        }}
      />
      <ZoneFilterButton {...props} />
      <BoolFilterButton
        label="Advanced"
        boolFilters={advancedBoolFilters}
        {...{ filters }}
      />
      {hasRangeFilter ? (
        <DateRangeFilterButton
          defaultValue="last12month"
          label="Last 12 months"
          labelPrefix="Historical: "
          filter={filters.range}
          clearButtonName="Reset"
          minDate="2021-04-14"
        />
      ) : null}
      <DiscountFilterButton {...props} />
    </>
  )
}

export default Filters
