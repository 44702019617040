import type { PurchaseOrder } from '@ttc/api/purchaseOrders'
import type { Supplier, SuppliersGetAllResponse } from '@ttc/api/suppliers'
import type { SelectOption } from '@ttc/api/types'
import type { Zone, ZonesGetAllResponse } from '@ttc/api/zones'
import EditPanel from 'components/EditPanel'
import EditPanelCard from 'components/EditPanelCard'
import Loading from 'components/Loading'
import useApi from 'hooks/useApi'
import find from 'lodash/find'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import Select from 'react-select'
import { Button, Col, FormGroup, Label, Row } from 'reactstrap'
import { showError } from 'utils/alert'

const CardSelectSupplier = ({ supplier, suppliers, handleChangeSupplier }) => {
  return (
    <EditPanelCard caption="Properties">
      <FormGroup>
        <Label className="mb-0" htmlFor="input-name">
          Supplier
        </Label>
        <Select
          placeholder="Select Supplier"
          value={supplier}
          onChange={handleChangeSupplier}
          options={suppliers}
        />
      </FormGroup>
    </EditPanelCard>
  )
}

const CardActions = ({ handleClickDelete, deleteButtonLabel }) => {
  return (
    <EditPanelCard caption="Actions" defaultIsOpen={false}>
      <Row>
        <Col>
          <FormGroup className="form-actions mt-3 text-center">
            <Button
              name="delete"
              size="lg"
              type="button"
              color="danger"
              onClick={handleClickDelete}
            >
              {deleteButtonLabel}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </EditPanelCard>
  )
}

type SelectSupplierPanelProps = {
  isOpen: boolean
  onClose: (isSaved?: boolean) => void
  onSave?: (supplierId: string) => Promise<void>
  onSaved?: (supplierId: string) => void
  purchaseOrder?: PurchaseOrder
  caption: string
  onClickDelete?: () => void
  deleteButtonLabel?: string
}

export const SelectSupplierPanel = (props: SelectSupplierPanelProps) => {
  const {
    isOpen,
    onClose,
    onSave,
    onSaved,
    purchaseOrder,
    caption,
    onClickDelete,
    deleteButtonLabel,
  } = props
  const [isLoading, setLoading] = useState<boolean>(false)
  const [supplier, setSupplier] = useState<SelectOption | null>(null)

  const apiLoadZones = useApi<ZonesGetAllResponse>(
    () => ({ action: 'zones_getAll' }),
    null,
    { autoPerform: isOpen, cache: true, freshCache: true },
  )

  const zones = useMemo(() => {
    return (apiLoadZones.result?.rows || []).map((row: Zone) => {
      return {
        value: String(row.id),
        label: `${row.name} - ${row.description}`,
      }
    })
  }, [apiLoadZones.result])

  const apiLoadSuppliers = useApi<SuppliersGetAllResponse>(
    () => ({ action: 'suppliers_getAll' }),
    null,
    { autoPerform: isOpen },
  )

  const suppliers = useMemo(() => {
    return (apiLoadSuppliers.result?.rows || []).map((row: Supplier) => {
      return { value: String(row.id), label: row.name }
    })
  }, [apiLoadSuppliers.result])

  useEffect(() => {
    if (isOpen && suppliers.length > 0 && zones.length > 0) {
      if (purchaseOrder?.supplier_id) {
        const supplier = find(suppliers, {
          value: String(purchaseOrder.supplier_id),
        })
        setSupplier(supplier)
      } else {
        setSupplier(null)
      }
    }
  }, [suppliers, isOpen, purchaseOrder, zones])

  const canSubmit =
    !isLoading && !apiLoadSuppliers.isLoading && supplier != null

  const saveData = useCallback(async () => {
    if (!supplier.value) {
      return
    }

    try {
      setLoading(true)

      if (onSave) {
        await onSave(supplier.value)
      }

      onClose(true)
      setLoading(false)

      if (onSaved) {
        onSaved(supplier.value)
      }
    } catch (e) {
      showError(e.message)
      setLoading(false)
    }
  }, [supplier, onClose, onSave, onSaved])

  const handleSubmit = useCallback(() => {
    saveData()
  }, [saveData])

  const handleChangeSupplier = useCallback((option: SelectOption) => {
    setSupplier(option)
  }, [])

  return (
    <EditPanel
      onSubmit={handleSubmit}
      caption={caption}
      {...{ isOpen, onClose, isLoading, canSubmit }}
    >
      {apiLoadSuppliers.isLoading ? (
        <Loading />
      ) : (
        <Fragment>
          {onClickDelete && deleteButtonLabel ? (
            <CardActions
              {...{ handleClickDelete: onClickDelete, deleteButtonLabel }}
            />
          ) : null}
          <CardSelectSupplier
            {...{
              suppliers,
              supplier,
              handleChangeSupplier,
              zones,
            }}
          />
        </Fragment>
      )}
    </EditPanel>
  )
}

export default SelectSupplierPanel
