import { usePrevious } from '@ttc/hooks'
import { EditPanel, EditPanelCard, Loading, SelectProducts } from 'components'
import { type StatefulState, useApi } from 'hooks'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { Button, Col, FormGroup, Row } from 'reactstrap'
import SelectVariation from './SelectVariation'
import type { SelectOption } from '@ttc/api/types'

type Props = {
  purchaseOrderId: number
  isOpen: boolean
  onClose: (isSaved?: boolean) => void
  onAddSimple: () => void
  onAddVariation: () => void
  onEditVariableProduct: (productId: number) => void
  addProducts: StatefulState<SelectOption[]>
}

const AddProductsPanel = ({
  purchaseOrderId,
  isOpen,
  onClose,
  onAddSimple,
  onAddVariation,
  onEditVariableProduct,
  addProducts,
}: Props) => {
  const addOrderItems = useApi(
    () => ({ action: 'purchaseOrders_addOrderItems' }),
    null,
    () => ({
      errorModal: true,
      throws: true,
    }),
  )

  const saveData = useCallback(async () => {
    if (!addProducts.value.length) {
      return
    }

    try {
      await addOrderItems.performRequest({
        json: {
          id: purchaseOrderId,
          product_ids: addProducts.value.map((p) => p.value),
        },
      })

      onClose(true)
    } catch (_e) {
      /* Ignore */
    }
  }, [purchaseOrderId, addProducts, onClose, addOrderItems])

  const handleChange = useCallback(
    (options: SelectOption[]) => {
      addProducts.set(options || [])
    },
    [addProducts],
  )

  const isLoading = addOrderItems.isLoading
  const canSubmit = !isLoading && addProducts && addProducts.value.length > 0

  const [step, setStep] = useState<
    'add-products' | 'create-new-select' | 'create-variation'
  >('add-products')

  // Reset form on open/close.
  const prevIsOpen = usePrevious(isOpen)
  useEffect(() => {
    if (isOpen !== prevIsOpen) {
      setStep('add-products')
      addProducts.set([])
    }
  }, [isOpen, prevIsOpen, addProducts])

  const handleClickCreateNewProduct = useCallback(() => {
    setStep('create-new-select')
  }, [])

  const handleClickAddVariable = useCallback(() => {
    setStep('create-variation')
  }, [])

  const handleClickCancelAddProduct = useCallback(() => {
    setStep('add-products')
  }, [])

  const handleEditVariableProduct = useCallback(
    ({ value }) => {
      onEditVariableProduct(value)
      setStep('add-products')
    },
    [onEditVariableProduct],
  )

  const handleClickAddSimple = useCallback(() => {
    onAddSimple()

    // Delay to avoid disrupting the slide animation.
    setTimeout(() => {
      setStep('add-products')
    }, 1000)
  }, [onAddSimple])

  const handleClickAddVariation = useCallback(() => {
    onAddVariation()

    // Delay to avoid disrupting the slide animation.
    setTimeout(() => {
      setStep('add-products')
    }, 1000)
  }, [onAddVariation])

  return (
    <EditPanel
      onSubmit={step === 'add-products' ? saveData : null}
      canSubmit={canSubmit && !isLoading}
      caption="Add Products"
      {...{ isOpen, onClose, isLoading }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <Fragment key={String(isOpen)}>
          <EditPanelCard caption="Products">
            {step === 'add-products' ? (
              <>
                <Row>
                  <Col>
                    <FormGroup className="mb-0">
                      <SelectProducts
                        value={addProducts.value}
                        onChange={handleChange}
                        placeholder="Select existing products"
                        cacheOptions={false}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      className="mt-3"
                      size="sm"
                      color="secondary"
                      onClick={handleClickCreateNewProduct}
                    >
                      Create new Product...
                    </Button>
                  </Col>
                </Row>
              </>
            ) : null}
            {step === 'create-new-select' ? (
              <>
                <Row>
                  <Col>
                    <Button
                      className="mb-2"
                      size="sm"
                      color="secondary"
                      onClick={handleClickAddVariable}
                    >
                      Create new Variation for existing product
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      className="mb-2"
                      size="sm"
                      color="secondary"
                      onClick={handleClickAddVariation}
                    >
                      Create new Variable product (plant)
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      className="mb-2"
                      size="sm"
                      color="secondary"
                      onClick={handleClickAddSimple}
                    >
                      Create new Simple product (addon)
                    </Button>
                  </Col>
                </Row>
              </>
            ) : null}
            {step === 'create-variation' ? (
              <>
                <Row>
                  <Col>Create new variation for product:</Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup className="mb-0">
                      <SelectVariation
                        onChange={handleEditVariableProduct}
                        placeholder="Select variable product"
                        value={null}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </>
            ) : null}
            {step === 'create-variation' || step === 'create-new-select' ? (
              <Row>
                <Col>
                  <Button
                    className="mt-2"
                    color="secondary"
                    size="sm"
                    onClick={handleClickCancelAddProduct}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            ) : null}
          </EditPanelCard>
        </Fragment>
      )}
    </EditPanel>
  )
}

export default AddProductsPanel
