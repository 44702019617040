import type { SelectOption } from '@ttc/api/types'
import { SelectSuppliers } from 'components'
import { useApi, useBoolean, useDropDownToggler, useStateful } from 'hooks'
import { useCallback, useEffect, useMemo } from 'react'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'

type SuppliersFilterButtonProps = {
  filter: FilterType
}

const SuppliersFilterButton = (props: SuppliersFilterButtonProps) => {
  const { filter } = props

  const toggler = useDropDownToggler()

  const handleChange = useCallback(
    (options: any[]) => {
      filter.setValues(options || [])
    },
    [filter],
  )

  const hasValue = filter.values.length > 0

  const names = useStateful<Record<string, string> | null>(null)

  // filter.value can be string[], if hydrated from query string
  const values = useMemo(() => {
    return filter.values.map((value: SelectOption | string) => {
      if (typeof value === 'object') {
        return value
      }

      const label = names.value ? names.value[value] : value

      return { label, value }
    })
  }, [filter.values, names])

  const isHydrated = useBoolean(false)

  const apiGetSupplier = useApi<{ id: number; name: string } | null>({
    action: 'suppliers_getRow',
  })

  // We need to fetch the supplier names if we have been hydrated from query string.
  useEffect(() => {
    if (values.length === 0 || isHydrated.value) {
      return
    }

    isHydrated.on()

    const load = async () => {
      const _names = {}

      for (const cat of values) {
        const id = Number(cat.value)

        const ret = await apiGetSupplier.performRequest({
          json: { id: Number(cat.value) },
        })

        _names[id] = ret.name
      }

      names.set(_names)
    }

    load()
  }, [apiGetSupplier, isHydrated, values, names])

  return (
    <Dropdown nav {...toggler}>
      <DropdownToggle nav caret className={hasValue ? 'hasValue' : ''}>
        {values.length === 0 ? (
          <span>Suppliers</span>
        ) : values.length === 1 ? (
          <span>{values[0].label}</span>
        ) : (
          <span>{values.length} Suppliers</span>
        )}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={filter.clear}>
          <i className="mr-1 fa fa-ban" /> Clear
        </DropdownItem>
        <div className="m-3" style={{ minWidth: 400, maxWidth: 600 }}>
          <SelectSuppliers
            placeholder="Suppliers"
            value={values}
            onChange={handleChange}
          />
        </div>
      </DropdownMenu>
    </Dropdown>
  )
}

export default SuppliersFilterButton
