import type {
  SubscriptionPlan,
  SubscriptionPlansGetResponse,
  SubscriptionPlansUpdateRequest,
} from '@ttc/api/subscriptions'
import type { SelectOption } from '@ttc/api/types'
import {
  EditPanel,
  EditPanelCard,
  LinkButton,
  Loading,
  SelectProducts,
} from 'components'
import { useApi, usePrevious } from 'hooks'
import type { PanelControlResult } from 'hooks/usePanelControl'
import { Fragment, useCallback, useEffect, useReducer } from 'react'
import { Button, Col, FormGroup, FormText, Label, Row } from 'reactstrap'
import { askQuestion } from 'utils'

const CardActions = ({
  handleClickActivate,
  handleClickDeactivate,
  canActivate,
  canDeactivate,
}) => {
  return (
    <EditPanelCard caption="Actions" defaultIsOpen={false}>
      <Row>
        <Col>
          <FormGroup className="form-actions mt-3 text-center">
            <Button
              name="activate"
              type="button"
              color="success"
              onClick={handleClickActivate}
              disabled={!canActivate}
            >
              Activate
            </Button>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup className="form-actions mt-3 text-center">
            <Button
              name="deactivate"
              type="button"
              color="warning"
              onClick={handleClickDeactivate}
              disabled={!canDeactivate}
            >
              Deactivate
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </EditPanelCard>
  )
}

type PlanEditorProps = {
  readOnly: boolean
  dispatch: (x: any) => void
  state: any
}

const PlanEditor = (props: PlanEditorProps) => {
  const { readOnly, dispatch, state } = props

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { type, name, value, checked } = e.currentTarget

      dispatch({
        type: 'SET_VALUE',
        name,
        value: type === 'checkbox' ? checked : value,
      })
    },
    [dispatch],
  )

  const handleChangeProduct = useCallback(
    (value: SelectOption) => {
      dispatch({
        type: 'SET_VALUE',
        name: 'product',
        value,
      })
    },
    [dispatch],
  )

  const handleChangeWelcomeProduct = useCallback(
    (value: SelectOption) => {
      dispatch({
        type: 'SET_VALUE',
        name: 'welcome_product',
        value,
      })
    },
    [dispatch],
  )

  const wpadminProductLink = state.product
    ? `${global.appConfig.wpBaseUrl}/wp-admin/post.php?action=edit&post=${state.product.value}`
    : null

  const wpadminWelcomeProductLink = state.welcome_product
    ? `${global.appConfig.wpBaseUrl}/wp-admin/post.php?action=edit&post=${state.welcome_product.value}`
    : null

  const wpadminCouponLink = state.discount_coupon_id
    ? `${global.appConfig.wpBaseUrl}/wp-admin/post.php?action=edit&post=${state.discount_coupon_id}`
    : null

  return (
    <EditPanelCard caption="Plan" stateId="plan">
      <FormGroup>
        <Label className="mb-0" htmlFor="input-discount-coupon-code">
          Discount Coupon Code:{' '}
          {wpadminCouponLink ? (
            <a target="_blank" rel="noreferrer" href={wpadminCouponLink}>
              (Open in wp-admin)
            </a>
          ) : null}
        </Label>
        <input
          className="form-control"
          id="input-discount-coupon-code"
          type="text"
          name="discount_coupon_code"
          value={state.discount_coupon_code || ''}
          onChange={handleChange}
          disabled={readOnly}
        />
        <FormText className="help-block">
          This is the percentage discount coupon code which will be applied
          automatically to the first order and subsequent membership orders.
        </FormText>
      </FormGroup>
      <FormGroup>
        <Label className="mb-0" htmlFor="input-free-shipping-threshold">
          Free Shipping Threshold in USD:
        </Label>
        <input
          className="form-control"
          id="input-free-shipping-threshold"
          type="text"
          name="free_shipping_threshold"
          value={state.free_shipping_threshold}
          onChange={handleChange}
          disabled={readOnly}
        />
        <FormText className="help-block">
          This overrides the default free shipping threshold.
        </FormText>
      </FormGroup>
      <FormGroup>
        <Label className="mb-0" htmlFor="input-product-id">
          Product:{' '}
          {wpadminProductLink ? (
            <a target="_blank" rel="noreferrer" href={wpadminProductLink}>
              (Open in wp-admin)
            </a>
          ) : null}
        </Label>
        <SelectProducts
          isMulti={false}
          value={state.product}
          onChange={handleChangeProduct}
          placeholder="Select product"
          filterStatus={['publish']}
          cacheOptions={false}
        />
        <FormText className="help-block">
          This is the "thrive membership" product which will appear as line item
          the the cart/checkout/order email etc.
        </FormText>
      </FormGroup>
      <FormGroup>
        <Label className="mb-0" htmlFor="input-gift-card-amount">
          Physical welcome gift card amount in USD:
        </Label>
        <input
          className="form-control"
          id="input-gift-card-amount"
          type="text"
          name="welcome_gift_card_amount"
          value={state.welcome_gift_card_amount}
          onChange={handleChange}
          disabled={readOnly}
        />
        <FormText className="help-block">
          This is used to display the amount of the physical gift in the cart.
        </FormText>
      </FormGroup>
      <FormGroup>
        <Label className="mb-0" htmlFor="input-product-id">
          Free Welcome Product:{' '}
          {wpadminWelcomeProductLink ? (
            <a
              target="_blank"
              rel="noreferrer"
              href={wpadminWelcomeProductLink}
            >
              (Open in wp-admin)
            </a>
          ) : null}
        </Label>
        <SelectProducts
          isMulti={false}
          value={state.welcome_product}
          onChange={handleChangeWelcomeProduct}
          placeholder="Select product"
          filterStatus={['private']}
          cacheOptions={false}
        />
        <FormText className="help-block">
          This is the product which will be added as a line item to the initial
          order (e.g. free gift card).
        </FormText>
      </FormGroup>
      <FormGroup>
        <Label className="mb-0" htmlFor="input-admin-icon">
          Admin Icon:
        </Label>
        <input
          className="form-control"
          id="input-admin-icon"
          type="text"
          name="admin_icon"
          value={state.admin_icon}
          onChange={handleChange}
          disabled={readOnly}
        />
        <FormText className="help-block">
          This icon is shown next to the customer name within Treeadmin.
        </FormText>
      </FormGroup>
      <div className="clearfix" />
    </EditPanelCard>
  )
}

type State = {
  discount_coupon_code: string
  free_shipping_threshold: string
  welcome_gift_card_amount: string
  product: { value: string; label: string } | null
  welcome_product: { value: string; label: string } | null
  admin_icon: string
}

const initialState: State = {
  discount_coupon_code: '',
  free_shipping_threshold: '',
  welcome_gift_card_amount: '',
  product: null,
  welcome_product: null,
  admin_icon: '',
}

type Action =
  | { type: 'SET_VALUE'; name: string; value: any }
  | { type: 'SET_VALUES'; values: Partial<State> }

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'SET_VALUE': {
      return { ...state, [action.name]: action.value }
    }
    case 'SET_VALUES': {
      return { ...initialState, ...action.values }
    }
    default:
      return state
  }
}

type EditPlanPanelProps = {
  readOnly: boolean
  isOpen: boolean
  onClose: () => void
  editId: string
  rowData?: SubscriptionPlan
  logPanel?: PanelControlResult
}

const EditPlanPanel = (props: EditPlanPanelProps) => {
  const { readOnly, isOpen, onClose, editId, logPanel } = props

  const [state, dispatch] = useReducer(reducer, initialState)

  const apiLoad = useApi<SubscriptionPlansGetResponse | null>(null, null, {
    errorModal: true,
  })
  const apiUpdate = useApi<any, SubscriptionPlansUpdateRequest>(null, null, {
    errorModal: true,
  })

  const prevIsOpen = usePrevious(isOpen)
  useEffect(() => {
    const fetchData = async () => {
      const ret = await apiLoad.performRequest({
        action: 'subscriptionPlans_get',
        json: { id: editId },
      })
      const values = {
        free_shipping_threshold: ret.free_shipping_threshold
          ? String(ret.free_shipping_threshold)
          : '',
        welcome_gift_card_amount: ret.welcome_gift_card_amount
          ? String(ret.welcome_gift_card_amount)
          : '',
        discount_coupon_code: ret.discount_coupon_code || '',
        admin_icon: ret.admin_icon || '',
        product: ret.product,
        welcome_product: ret.welcome_product,
      }
      dispatch({ type: 'SET_VALUES', values })
    }

    if (isOpen && isOpen !== prevIsOpen) {
      if (editId == null) {
        dispatch({ type: 'SET_VALUES', values: initialState })
      } else {
        fetchData()
      }
    }
  }, [apiLoad, prevIsOpen, isOpen, editId])

  const canActivate = apiLoad.result?.can_activate
  const canDeactivate = apiLoad.result?.can_deactivate

  const handleSubmit = useCallback(async () => {
    const ret = await apiUpdate.performRequest({
      action: 'subscriptionPlans_update',
      json: {
        id: editId,
        ...state,
        product_id: state.product?.value,
        welcome_product_id: state.welcome_product?.value,
      },
    })

    if (ret) {
      onClose()
    }
  }, [onClose, state, apiUpdate, editId])

  const canSubmit = true

  const isLoading = apiLoad.isLoading || apiUpdate.isLoading

  const handleClickActivate = useCallback(async () => {
    if (
      !(await askQuestion(
        'Are you sure you want to activate this plan? This will deactivate any other currently activated plan.',
      ))
    ) {
      return
    }

    await apiUpdate.performRequest({
      action: 'subscriptionPlans_activate',
      json: { id: editId },
    })

    onClose()
  }, [apiUpdate, onClose, editId])

  const handleClickDeactivate = useCallback(async () => {
    if (
      !(await askQuestion(
        "Are you sure you want to deactivate this plan? New users won't be able to subscribe. Existing subscriptions will continue.",
      ))
    ) {
      return
    }

    await apiUpdate.performRequest({
      action: 'subscriptionPlans_deactivate',
      json: { id: editId },
    })

    onClose()
  }, [apiUpdate, onClose, editId])

  const handleClickShowLog = useCallback(async () => {
    logPanel?.open()
  }, [logPanel])

  const caption = (
    <>
      Edit Plan{' '}
      <LinkButton onClick={handleClickShowLog} className="small">
        (Show Log)
      </LinkButton>
    </>
  )

  return (
    <EditPanel
      onSubmit={readOnly || isLoading ? null : handleSubmit}
      {...{ isOpen, onClose, caption, isLoading, canSubmit }}
    >
      {apiLoad.isLoading ? (
        <Loading />
      ) : (
        <Fragment key={JSON.stringify({ isOpen, editId })}>
          {!readOnly && editId != null ? (
            <CardActions
              {...{
                handleClickActivate,
                handleClickDeactivate,
                canActivate,
                canDeactivate,
              }}
            />
          ) : null}
          <PlanEditor {...{ readOnly, dispatch, state }} />
          {apiUpdate.isLoading ? <Loading /> : null}
        </Fragment>
      )}
    </EditPanel>
  )
}

export default EditPlanPanel
