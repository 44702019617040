import { CellEditable } from 'components/Table'
import type { ColumnDef } from 'components/Table/types'
import UnassignedInfo from './UnassignedInfo'
import { Badge } from 'reactstrap'
import { formatPrice } from 'utils/price'

const renderPriceValue = (value: string) => {
  return value && value !== '0.00' ? `$${formatPrice(Number(value) * 100)}` : ''
}

const priceRenderer = (props) => {
  return <td>{renderPriceValue(props.value)}</td>
}

const ratioRenderer = (props) => {
  return <td>{props.value ? formatPrice(Number(props.value) * 100) : ''}</td>
}

const columnDef: ColumnDef[] = [
  {
    label: '',
    id: 'checkbox',
    sortColumn: false,
    canExport: false,
  },
  {
    label: 'ID',
    id: 'id',
    sortColumn: true,
    defaultVisible: false,
    hasClipboardButton: true,
  },
  {
    label: 'DS Product ID',
    id: 'md_product_id',
    sortColumn: true,
    defaultVisible: false,
    hasClipboardButton: true,
  },
  {
    label: 'DS Variant ID',
    id: 'md_variant_id',
    sortColumn: true,
    defaultVisible: false,
    hasClipboardButton: true,
  },
  {
    label: 'DS Variant SKU',
    id: 'md_variant_sku',
    sortColumn: true,
    defaultVisible: true,
    hasClipboardButton: true,
  },
  {
    label: 'DS Product Name',
    id: 'product_name',
    sortColumn: true,
    defaultVisible: true,
  },
  {
    label: 'DS Variation Name',
    id: 'variation_name',
    sortColumn: true,
    defaultVisible: true,
  },
  {
    label: 'Status',
    id: 'is_enabled',
    title:
      'Indicates if the product is available for sale from our side or not',
    sortColumn: true,
    defaultVisible: true,
    renderer: ({ value }) => {
      const isEnabled = Number(value) === 1
      const statusColor = isEnabled ? 'success' : 'secondary'

      return (
        <td>
          <Badge color={statusColor}>{isEnabled ? 'ACTIVE' : 'INACTIVE'}</Badge>
        </td>
      )
    },
    filterDef: {
      type: 'select-one',
      options: [
        { label: 'ACTIVE', value: 'active' },
        { label: 'INACTIVE', value: 'inactive' },
      ],
    },
  },
  {
    label: 'Pack Count',
    id: 'pack',
    title: 'Number of items in a pack',
    sortColumn: true,
    defaultVisible: false,
    filterDef: {
      type: 'select-one',
      options: [
        { label: 'SINGLE', value: 'single' },
        { label: 'MULTI', value: 'multi' },
      ],
    },
  },
  {
    label: 'DS Inventory',
    id: 'inventory_amount',
    sortColumn: true,
    defaultVisible: true,
    filterDef: {
      type: 'select-one',
      options: [
        { label: 'Non zero', value: 'non-zero' },
        { label: 'Zero', value: 'zero' },
      ],
    },
  },
  {
    label: 'Zone Inventory',
    title:
      'Zone Qty for a single item (i.e. DS Inventory minus threshold to prevent overselling)',
    id: 'zone_qty',
    sortColumn: true,
    defaultVisible: true,
    renderer: ({ row, value }) => {
      return (
        <td data-col-id="zone_qty" title={row.zone_qty_info}>
          {value}
        </td>
      )
    },
  },
  {
    label: 'TTC Product Variation',
    id: 'ttc_product_variation_id',
    sortColumn: false,
    defaultVisible: true,
    isProductSelect: true,
    renderer: (props) => {
      const isAssigned = props.row.ttc_product_sku != null

      if (isAssigned) {
        const sku = props.row.ttc_product_sku
        const name = props.row.ttc_product_name
        const size = props.row.ttc_product_size

        const value = name
          ? `${name} ${size} (${sku})`
          : props.row.ttc_product_name

        return <CellEditable {...props} value={value} />
      }
      return (
        <CellEditable
          {...props}
          value={props.row.product_name}
          displayValue={isAssigned ? null : <UnassignedInfo />}
        />
      )
    },
    filterDef: {
      type: 'select-one',
      options: [
        { label: 'Assigned', value: 'assigned' },
        { label: 'Unassigned', value: 'unassigned' },
      ],
    },
  },
  {
    label: 'TTC Price',
    id: 'ttc_price',
    sortColumn: true,
    defaultVisible: true,
    isNumeric: true,
    renderer: priceRenderer,
  },
  {
    label: 'DS Base Price',
    id: 'md_base_price',
    sortColumn: true,
    defaultVisible: true,
    isNumeric: true,
    renderer: priceRenderer,
  },
  {
    label: 'DS Retail Price',
    id: 'md_retail_price',
    sortColumn: true,
    defaultVisible: true,
    isNumeric: true,
    renderer: priceRenderer,
  },
  {
    title: 'DS Base Price / DS Retail Price',
    label: 'DS Price %',
    id: 'price_ratio_base_retail',
    sortColumn: true,
    defaultVisible: false,
    renderer: ratioRenderer,
  },
  {
    title: 'DS Base Price / TTC Price',
    label: 'TTC Price %',
    id: 'price_ratio_base_ttc',
    sortColumn: true,
    defaultVisible: false,
    renderer: ratioRenderer,
  },
]

export default columnDef
