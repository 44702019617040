import { apiRequest } from 'api'
import type { MultiValue, OptionsOrGroups } from 'react-select'
import AsyncSelect from 'react-select/async'
import AsyncCreatable from 'react-select/async-creatable'
import styles from './../selectStyles'
import type { SelectOption } from '@ttc/api/types'

const formatCreateLabel = (label: string) =>
  `Create new customer with email: ${label}`

const handleIsValidNewOption = (option: string) => {
  return /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(option)
}

const cache = {}

const loadOptions = async (
  query: string,
  callback: (options: SelectOption[]) => void,
  readOnly: boolean,
): Promise<any> => {
  try {
    let options = []

    if (Object.hasOwn(cache, query)) {
      options = cache[query]
    } else {
      const json = await apiRequest({ action: 'customers_search', query })

      options = Object.keys(json).map((customerId) => {
        const label = json[customerId]

        return { value: customerId, label }
      })

      cache[query] = options
    }

    if (!readOnly) {
      options = [
        {
          value: 'guest',
          label: 'Guest without email address',
        },
        ...options,
      ]
    }

    callback(options)
  } catch (_e) {
    return
  }
}

type SelectCustomerProps = {
  value: string
  onSelect: (newValue: MultiValue<string>) => void
  className?: string
  readOnly?: boolean
}

const SelectCustomer = (props: SelectCustomerProps) => {
  const { value, onSelect, className, readOnly } = props

  const selectProps = {
    inputProps: {
      autoComplete: 'nope',
      autoCorrect: 'off',
      spellCheck: 'off',
    },
    styles,
    isClearable: true,
    escapeClearsValue: true,
    cacheOptions: false,
    autoload: false,
    formatCreateLabel,
    loadOptions: (
      inputValue: string,
      cb: (options: OptionsOrGroups<any, any>) => void,
    ) => loadOptions(inputValue, cb, readOnly),
    className: `select-customer ${className}`,
    resetValue: '',
    placeholder: 'Search customers',
    value,
    onChange: onSelect,
    isValidNewOption: handleIsValidNewOption,
    'aria-label': 'Select customer',
  }

  return readOnly ? (
    <AsyncSelect {...selectProps} />
  ) : (
    <AsyncCreatable {...selectProps} />
  )
}

export default SelectCustomer
